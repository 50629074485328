@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background: #ffffff;
}
